import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

// APIs
import watchlistApi from 'lib/api/watchlist';
import friendsApi from 'lib/api/friends';

// interfaces
import {
  followUserParams,
  getFollowersParams,
  getFollowersPayload,
  getFollowingParams,
  getFollowingPayload,
  GetMutualFollowerParams,
  GetMutualFollowerPayload,
} from './slice.types';

// Initial State
const initialState = {
  followers: {
    company: {},
    user: {},
    isLoading: false,
    error: null,
    message: '',
    pagination: {
      more: true,
      page: 1,
      search: undefined,
    },
  },
  following: {
    user: {},
    isLoading: false,
    error: null,
    message: '',
    pagination: {
      more: true,
      page: 1,
      search: undefined,
    },
  },
  mutualFollower: {
    user: {},
    isLoading: false,
    error: null,
    message: '',
    pagination: {
      more: true,
      page: 1,
      search: undefined,
    },
  },
};

type ModerationState = typeof initialState;

// Selectors
const moderationState = (state) => state.moderation;
export const selectors = createSelector(
  moderationState,
  (moderation: ModerationState) => ({
    followers: moderation.followers,
    userFollowers: moderation.followers.user,
    companyFollowers: moderation.followers.company,
    isLoadingFollower: moderation.followers.isLoading,
    followersPagination: moderation.followers.pagination,

    following: moderation.following,
    userFollowing: moderation.following.user,
    isLoadingFollowing: moderation.following.isLoading,
    followingPagination: moderation.following.pagination,

    mutualFollower: moderation.mutualFollower,
    userMutualFollower: moderation.mutualFollower.user,
    isLoadingMutualFollower: moderation.mutualFollower.isLoading,
    mutualFollowerPagination: moderation.mutualFollower.pagination,
  }),
);

// Action types
const CONTEXT = '@redux/moderation';

const actionType = {
  GET_FOLLOWER: `${CONTEXT}/GET_FOLLOWER`,
  GET_FOLLOWING: `${CONTEXT}/GET_FOLLOWING`,
  GET_MUTUAL_FOLLOWER: `${CONTEXT}/GET_MUTUAL_FOLLOWER`,
  TOGGLE_FOLLOW_USER: `${CONTEXT}/TOGGLE_FOLLOW_USER`,
};

// Side effects
export const effects = {
  getFollowers: createAsyncThunk<getFollowersPayload, getFollowersParams>(
    actionType.GET_FOLLOWER,
    async ({ page = 1, keyword, companyid: symbol, userid: username }) => {
      try {
        let response;

        if (symbol) {
          response = await watchlistApi.getCompanyFollowers(symbol, {
            page,
            keyword,
          });
        } else {
          response = await watchlistApi.getPeopleFollowers(username, {
            page,
            keyword,
          });
        }

        if (!response.data) {
          throw new Error('Attempt to get followers failed');
        }

        const { error_type: error, data, message } = response.data;
        if (error) {
          return { error, message };
        }

        return {
          data: data.users,
          more: data.more,
          message,
          username,
          symbol,
        };
      } catch (error) {
        return { error };
      }
    },
  ),
  getUserFollowing: createAsyncThunk<getFollowingPayload, getFollowingParams>(
    actionType.GET_FOLLOWING,
    async ({ page = 1, keyword, userid }) => {
      try {
        const response = await watchlistApi.getPeopleFollowing(userid, {
          page,
          keyword,
        });

        if (!response.data) {
          throw new Error('Attempt to get user following failed');
        }

        const { error_type: error, data, message } = response.data;

        if (error) {
          return { error, message };
        }

        return { data: data.users, more: data.more, message, userid };
      } catch (error) {
        return { error };
      }
    },
  ),
  getMutualFollower: createAsyncThunk<
    GetMutualFollowerPayload,
    GetMutualFollowerParams
  >(
    actionType.GET_MUTUAL_FOLLOWER,
    async ({ page = 1, keyword, userid, limit = 10 }) => {
      try {
        const response = await friendsApi.getFollowedBy({
          page,
          keyword,
          limit,
          userid,
        });

        if (!response.data) {
          throw new Error('Attempt to get followed by failed');
        }

        const { error_type: error, data, message } = response.data;

        if (error) {
          return { error, message };
        }

        return { data, message, userid };
      } catch (error) {
        return { error };
      }
    },
  ),
  followUser: createAsyncThunk<any, followUserParams>(
    actionType.TOGGLE_FOLLOW_USER,
    async ({ userid, type = 'FOLLOW' }) => {
      try {
        if (!userid) {
          throw new Error('User ID is required');
        }

        let targetAPI = watchlistApi.postFollowUser;
        if (type === 'UNFOLLOW') {
          targetAPI = watchlistApi.postUnfollowUser;
        }

        const response = await targetAPI(userid);

        if (!response.data) {
          throw new Error('Attempt to follow user failed');
        }

        const { error_type: error, data, message } = response.data;

        if (error) {
          return { error, message };
        }

        return { data, message };
      } catch (error) {
        return { error };
      }
    },
  ),
};

// Reducers
const reducers = {
  resetFollowersState: (draft: ModerationState) => {
    draft.followers.pagination.more = true;
    draft.followers.pagination.page = 1;
    draft.followers.pagination.search = undefined;
    draft.followers.company = {};
    draft.followers.user = {};
  },
  resetFollowingState: (draft: ModerationState) => {
    draft.following.pagination.more = true;
    draft.following.pagination.page = 1;
    draft.following.pagination.search = undefined;
    draft.following.user = {};
  },
  resetMutualFollowerState: (draft: ModerationState) => {
    draft.mutualFollower.pagination.more = true;
    draft.mutualFollower.pagination.page = 1;
    draft.mutualFollower.pagination.search = undefined;
    // draft.mutualFollower.user = {};
  },
  searchFollowers: (draft: ModerationState, action: PayloadAction<string>) => {
    draft.followers.pagination.more = true;
    draft.followers.pagination.page = 1;
    draft.followers.pagination.search = action.payload;
  },
  searchFollowing: (draft: ModerationState, action: PayloadAction<string>) => {
    draft.following.pagination.more = true;
    draft.following.pagination.page = 1;
    draft.following.pagination.search = action.payload;
  },
  searchMutualFollower: (
    draft: ModerationState,
    action: PayloadAction<string>,
  ) => {
    draft.mutualFollower.pagination.more = true;
    draft.mutualFollower.pagination.page = 1;
    draft.mutualFollower.pagination.search = action.payload;
  },
};

const extraReducers = (builder) => {
  builder
    .addCase(
      effects.getFollowers.pending,
      (
        state: ModerationState,
        action: PayloadAction<getFollowersPayload, any, any>,
      ) => {
        state.followers.isLoading = true;
        state.followers.error = null;

        const { page, userid: username, companyid: symbol } = action.meta.arg;
        if (page === 1) {
          if (symbol) {
            state.followers.company[symbol] = [];
          } else if (username) {
            state.followers.user[username] = [];
          }
        }
      },
    )
    .addCase(
      effects.getFollowers.fulfilled,
      (
        state: ModerationState,
        action: PayloadAction<getFollowersPayload, any, any>,
      ) => {
        const { error, data, message, username, symbol, more } = action.payload;
        if (error) {
          state.followers.error = error;
        } else {
          const followersData = data || [];
          if (username) {
            state.followers.user[username]?.push(...followersData);
          } else if (symbol) {
            state.followers.company[symbol]?.push(...followersData);
          }
        }

        state.followers.isLoading = false;
        state.followers.message = message;
        state.followers.pagination.more = more;
        state.followers.pagination.page = action.meta.arg.page + 1;
      },
    )
    .addCase(
      effects.getFollowers.rejected,
      (
        state: ModerationState,
        action: PayloadAction<getFollowersPayload, any, any>,
      ) => {
        state.followers.isLoading = false;
        state.followers.error = action.payload.error;
        state.followers.pagination.more = false;
        state.followers.pagination.page = 1;
      },
    )

    .addCase(
      effects.getUserFollowing.pending,
      (
        state: ModerationState,
        action: PayloadAction<getFollowingPayload, any, any>,
      ) => {
        state.following.isLoading = true;
        state.following.error = null;

        const { page, userid } = action.meta.arg;
        if (page === 1) {
          state.following.user[userid] = [];
        }
      },
    )
    .addCase(
      effects.getUserFollowing.fulfilled,
      (
        state: ModerationState,
        action: PayloadAction<getFollowingPayload, any, any>,
      ) => {
        const { error, data, message, userid, more } = action.payload;
        if (error) {
          state.following.error = error;
        } else {
          const followingData = data || [];
          state.following.user[userid].push(...followingData);
        }

        state.following.isLoading = false;
        state.following.message = message;
        state.following.pagination.more = more;
        state.following.pagination.page = action.meta.arg.page + 1;
      },
    )
    .addCase(
      effects.getUserFollowing.rejected,
      (
        state: ModerationState,
        action: PayloadAction<getFollowingPayload, any, any>,
      ) => {
        state.following.isLoading = false;
        state.following.error = action.payload.error;
        state.following.pagination.more = false;
        state.following.pagination.page = 1;
      },
    )

    .addCase(
      effects.getMutualFollower.pending,
      (
        state: ModerationState,
        action: PayloadAction<GetMutualFollowerPayload, any, any>,
      ) => {
        state.mutualFollower.isLoading = true;
        state.mutualFollower.error = null;

        const { userid } = action.meta.arg;
        if (!state.mutualFollower.user?.[userid]) {
          state.mutualFollower.user[userid] = {
            data: [],
            total_follower: 0,
          };
        }
      },
    )
    .addCase(
      effects.getMutualFollower.fulfilled,
      (
        state: ModerationState,
        action: PayloadAction<GetMutualFollowerPayload, any, any>,
      ) => {
        const { userid, page = 1 } = action.meta.arg;
        const { error, data, message } = action.payload;
        if (error) {
          state.mutualFollower.error = error;
        } else {
          const users = data.user || [];
          state.mutualFollower.user[userid].data =
            page > 1
              ? [...state.mutualFollower.user[userid].data, ...users]
              : users;
          state.mutualFollower.user[userid].total_follower =
            data.total_follower;
        }

        state.mutualFollower.isLoading = false;
        state.mutualFollower.message = message;
        state.mutualFollower.pagination.more =
          state.mutualFollower.user[userid]?.data?.length < data?.total_follower;
        state.mutualFollower.pagination.page = page + 1;
      },
    )
    .addCase(
      effects.getMutualFollower.rejected,
      (
        state: ModerationState,
        action: PayloadAction<getFollowingPayload, any, any>,
      ) => {
        state.mutualFollower.isLoading = false;
        state.mutualFollower.error = action.payload.error;
        state.mutualFollower.pagination.more = false;
        state.mutualFollower.pagination.page = 1;
      },
    );
};

const moderationSlice = createSlice({
  name: 'moderation',
  initialState,
  reducers,
  extraReducers,
});

export default moderationSlice;
