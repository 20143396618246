/* eslint-disable array-callback-return */
import streamApi from 'lib/api/stream';
import { ALLOWED_DOC_MIMETYPE, ALLOWED_IMAGE_MIMETYPE } from 'features/stream/constants';
import { IMAGE_TYPE_COMMON } from 'global/StreamCompose/constants';
import ERROR_MESSAGE from 'constants/errorMessage';
import alertMessage from 'global/AlertMessage';
import uploadHandler from './uploadHandler';

const uploadAllStreamFiles = (files = []) => new Promise((resolve, reject) => {
  // prepare filenames url param
  const filename = new URLSearchParams();
  files.map((item) => {
    filename.append('filenames', item.file.name);
  });

  streamApi.getUploadTokenV2(filename)
    .then((res) => {
      const {
        data: {
          tokens,
          url,
        },
      } = res.data;
      const collection = [];
      const fileUrl = [];

      // Loop file to generate promise
      tokens.map((item, index) => {
        const uploadPromise = uploadHandler(files[index].file, url, item.fields, 'stream');
        collection.push(uploadPromise);
        fileUrl.push({ url: item.view_url });

        return item;
      });

      // Upload all file in collection of fetch
      return Promise.all(collection)
        .then(() => {
          resolve({ data: fileUrl });
        })
        .catch((err) => {
          reject(new Error(err));
        });
    })
    .catch((err) => {
      reject(err);
    });
});

export const uploadImages = async (images = []) => {
  let imageList = [];
  try {
    const uploadedImage = images.filter((item) => {
      const fileExt = item.file.name.split('.').pop();
      return ALLOWED_IMAGE_MIMETYPE.indexOf(fileExt.toLowerCase()) !== -1;
    });

    if (images.length > 0 && !uploadedImage[0]) {
      alertMessage({
        content: ERROR_MESSAGE.UPLOAD_IMAGE_TYPE_NOT_SUPPORTED,
        alertType: 'snackbar',
        messageType: 'error',
        hasCloseIcon: true,
      });
      throw new Error(ERROR_MESSAGE.UPLOAD_IMAGE_TYPE_NOT_SUPPORTED);
    }

    const uploadFile: any = await uploadAllStreamFiles(images);

    imageList = uploadFile.data.map((item) => item.url);
    return { data: imageList };
  } catch (error) {
    throw new Error(error);
  }
};

export const uploadFileDocuments = async (files = []) => {
  let fileList = [];
  try {
    const uploadedDocuments = files.filter((item) => {
      const fileExt = item.file.name.split('.').pop();
      return ALLOWED_DOC_MIMETYPE.indexOf(fileExt.toLowerCase()) !== -1;
    });

    if (files.length > 0 && !uploadedDocuments[0]) {
      alertMessage({
        content: ERROR_MESSAGE.UPLOAD_DOCUMENT_TYPE_NOT_SUPPORTED,
        alertType: 'snackbar',
        messageType: 'error',
        hasCloseIcon: true,
      });
      throw new Error(ERROR_MESSAGE.UPLOAD_DOCUMENT_TYPE_NOT_SUPPORTED);
    }

    const uploadFile: any = await uploadAllStreamFiles(files);

    fileList = uploadFile.data.map((item) => item.url);
    return { data: fileList };
  } catch (error) {
    throw new Error(error);
  }
};

/**
 * Upload file from stream compose to Amazon S3
 * @param {Array} files
 * @returns {Object}
 */
const uploadStreamFiles = async (file, image) => {
  const files = [];
  const images = [];

  if (file.length > 0 || image.length > 0) {
    try {
      const fileList = [];
      // sort out new document and existing document
      file.map((data) => {
        if (typeof data === 'string') {
          files.push(data);
        } else {
          fileList.push({ file: data, type: 'file' });
        }
      });
      const imageList = [];
      // sort out new image and existing image
      image.map((data) => {
        if (data?.type === IMAGE_TYPE_COMMON && data?.isNew) {
          imageList.push({ file: data?.file, type: 'image' });
        } else {
          images.push(data.file);
        }
      });

      const uploadedDocuments = fileList.length > 0 && await uploadFileDocuments(fileList);
      const uploadedImages = imageList.length > 0 && await uploadImages(imageList);

      const { data: listOfImageUrl = [] } = uploadedImages;
      const { data: listOfDocumentUrl = [] } = uploadedDocuments;

      if (listOfImageUrl.length > 0) {
        images.push(...listOfImageUrl);
      }
      if (listOfDocumentUrl.length > 0) {
        files.push(...listOfDocumentUrl);
      }
    } catch (error) {
      return { error };
    }
  }
  return { file: files.join(), image: images };
};

export default uploadStreamFiles;
