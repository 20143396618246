/* eslint-disable no-undef */
import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

// APIs
import streamApi from 'lib/api/stream';
import friendsApi from 'lib/api/friends';
import { transformStreamV3 } from 'utils/stream';
import { CommonPayload } from '../../../@types/action-payload';

// Initial state
const initialState = {
  pinnedPost: {
    data: {},
    isLoading: false,
    message: '',
    error: null,
  },
  followedBy: {
    data: {},
    isLoading: false,
  },
};

type UserProfileState = typeof initialState;

// selectors
const userProfile = (state) => state.userProfile;
export const selectors = createSelector(userProfile, (profile) => ({
  pinnedPost: profile.pinnedPost,
  followedBy: profile.followedBy,
}));

// Action types
const CONTEXT = '@features/user-profile';
const actionType = {
  GET_PINNED_POST: `${CONTEXT}/GET_PINNED_POST`,
  GET_FOLLOWED_BY: `${CONTEXT}/GET_FOLLOWED_BY`,
};

interface PinnedPostPayload extends CommonPayload<Record<string, any>> {
  username?: string;
}

interface getPinnedProps {
  username: string;
}

// side effects
export const effects = {
  getPinnedPost: createAsyncThunk<PinnedPostPayload, getPinnedProps>(
    actionType.GET_PINNED_POST,
    async ({ username }) => {
      try {
        if (!username) {
          throw new Error('Username is required!');
        }

        const response = await streamApi.getPinnedPost(username);

        if (!response.data) {
          throw new Error('Attempt to get user pinned post failed');
        }

        const { error_type: error, data: postData, message } = response.data;

        if (error) {
          return { error, message };
        }

        const data = transformStreamV3(postData);

        return { data, message, username };
      } catch (error) {
        return { error, username };
      }
    },
  ),
  getFollowedBy: createAsyncThunk<CommonPayload, any>(
    actionType.GET_FOLLOWED_BY,
    async (params) => {
      try {
        const {
          page = 1,
          keyword = null,
          limit = 10,
          userId,
          username,
        } = params;

        const response = await friendsApi.getFollowedBy({
          page,
          keyword,
          limit,
          userid: userId,
        });

        if (!response.data) {
          throw new Error('Attempt to get followed by failed');
        }

        const { error_type: error, data, message } = response.data;

        if (error) {
          return { error, message };
        }

        return { data, message, username };
      } catch (error) {
        return { error };
      }
    },
  ),
};

// Reducers
const reducers = {};

const extraReducers = (builder) => {
  builder
    .addCase(effects.getPinnedPost.pending, (state: UserProfileState) => {
      state.pinnedPost.isLoading = true;
      state.pinnedPost.error = null;
    })
    .addCase(
      effects.getPinnedPost.fulfilled,
      (state: UserProfileState, action: PayloadAction<PinnedPostPayload>) => {
        const { error, message, data, username } = action.payload;

        if (error) {
          state.pinnedPost.data[username] = undefined;
          state.pinnedPost.error = error;
        } else {
          state.pinnedPost.data[username] = data;
          state.pinnedPost.error = null;
        }

        state.pinnedPost.message = message;
        state.pinnedPost.isLoading = false;
      },
    )
    .addCase(
      effects.getPinnedPost.rejected,
      (state: UserProfileState, action: PayloadAction<PinnedPostPayload>) => {
        const { error, username } = action.payload;

        state.pinnedPost.isLoading = false;
        state.pinnedPost.data[username] = undefined;
        state.pinnedPost.error = error;
      },
    )

    // Followed By
    .addCase(effects.getFollowedBy.pending, (state: UserProfileState) => {
      state.followedBy.isLoading = true;
    })
    .addCase(
      effects.getFollowedBy.fulfilled,
      (state: UserProfileState, action: PayloadAction<any, any, any>) => {
        const { data, username } = action.payload;
        const { page = 1 } = action.meta.arg;

        if (data) {
          if (page === 1) {
            state.followedBy.data[username] = data;
          } else if (page > 1) {
            state.followedBy.data[username] = {
              total_follower: data.total_follower,
              user: [...state.followedBy.data[username].user, ...data.user],
            };
          }
        }
        state.followedBy.isLoading = false;
      },
    );
};

const userProfileSlice = createSlice({
  name: 'userProfile',
  initialState,
  reducers,
  extraReducers,
});

export default userProfileSlice;
