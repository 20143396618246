import {
  createAsyncThunk,
  createSlice,
  createSelector,
} from '@reduxjs/toolkit';

// APIs
import uploadStreamFiles from 'utils/uploadStreamFiles';
import streamApi from 'lib/api/stream';

import SUCCESS_MESSAGE from 'constants/successMessage';
import ERROR_MESSAGE from 'constants/errorMessage';
import alertMessage from 'global/AlertMessage';
import { transformStreamV3 } from 'utils/stream';

// Initial state
const initialState = {
  composeStream: {
    data: {},
    isLoading: false,
    success: false,
    error: null,
    message: '',
  },
  updatePostStream: {
    data: {},
    isLoading: false,
    error: null,
    message: '',
  },
  shareStream: {
    data: {},
    isLoading: false,
    error: null,
    message: '',
  },
  composeConversationStream: {
    data: {},
    isLoading: false,
    error: null,
    message: '',
  },
};

type StreamState = typeof initialState;

// Selectors
const streamSelector = (state) => state.entities.stream;
export const selectors = createSelector(
  streamSelector,
  (stream: StreamState) => ({
    composeStream: stream.composeStream,
    shareStream: stream.shareStream,
    updatePostStream: stream.updatePostStream,
    composeConversation: stream.composeConversationStream,
  }),
);

// Action types
const CONTEXT = '@redux/stream';

const actionType = {
  POST_STREAM: `${CONTEXT}/post`,
  UPDATE_POST_STREAM: `${CONTEXT}/update_post`,
  SHARE_STREAM: `${CONTEXT}/share_post`,
  POST_CONVERSATION_STREAM: `${CONTEXT}/post_conversation`,
};

// Effects
export const effects = {
  postStream: createAsyncThunk(
    actionType.POST_STREAM,
    // @ts-ignore
    async (streamData: any) => {
      const { file, image, isPinned, ...otherStreamDatas } = streamData;

      try {
        // handle upload file
        const dataUpload = await uploadStreamFiles(file, image);

        // Cancel post process if there's an error uploading file
        if (dataUpload?.error) {
          throw new Error(ERROR_MESSAGE.STREAM_ERROR_UPLOAD_FILE);
        }

        const dataStream = {
          ...otherStreamDatas,
          file: dataUpload.file,
          images: dataUpload.image,
        };

        const response = await streamApi.postStream(dataStream);

        if (!response.data) {
          throw new Error('Attempt to post Compose stream Failed!');
        }

        const { data, message, error } = response.data;

        if (error) {
          return { error, message };
        }

        // show alert success
        alertMessage({
          content: SUCCESS_MESSAGE.POST_SUCCESSFUL,
          alertType: 'snackbar',
          messageType: 'success',
          hasCloseIcon: true,
        });

        // add pin post stream action if post will be pinned
        const { postid: postID } = data;
        if (isPinned && postID) {
          const pinPostResponse = await streamApi.pinPost(postID, null);

          if (!pinPostResponse.data) {
            throw new Error('Attempt to pin post Failed!');
          }
        }

        return { data, message };
      } catch (error) {
        return { error };
      }
    },
  ),
  updatePostStream: createAsyncThunk(
    actionType.UPDATE_POST_STREAM,
    // @ts-ignore
    async (streamData: any) => {
      const { file, image, ...otherStreamDatas } = streamData;
      try {
        // handle upload file
        const dataUpload = await uploadStreamFiles(file, image);

        // Cancel post process if there's an error uploading file
        if (dataUpload?.error) {
          throw new Error(ERROR_MESSAGE.STREAM_ERROR_UPLOAD_FILE);
        }

        const dataStream = {
          ...otherStreamDatas,
          file: dataUpload.file,
          images: dataUpload.image,
        };
        // handle write post

        const update = await streamApi.updatePostStream(dataStream);

        if (!update.data) {
          throw new Error('Attempt to post Compose stream Failed!');
        }

        const response = await streamApi.getStreamDetail(otherStreamDatas?.postid);

        if (!response.data) {
          throw new Error('Attempt to get stream Failed!');
        }

        const { data: postData, message, error } = response.data;

        if (error) {
          return { error, message };
        }

        // show alert success
        alertMessage({
          content: SUCCESS_MESSAGE.POST_SUCCESSFUL_UPDATE,
          alertType: 'snackbar',
          messageType: 'success',
          hasCloseIcon: true,
        });

        const data = transformStreamV3(postData);
        return { data, message };
      } catch (error) {
        return { error };
      }
    },
  ),
  shareStream: createAsyncThunk(
    actionType.SHARE_STREAM,
    // @ts-ignore
    async (streamData: any) => {
      const { file, image, ...otherStreamDatas } = streamData;

      try {
        const dataUpload = await uploadStreamFiles(file, image);

        // Cancel post process if there's an error uploading file
        if (dataUpload?.error) {
          throw new Error(ERROR_MESSAGE.STREAM_ERROR_UPLOAD_FILE);
        }

        const dataStream = {
          ...otherStreamDatas,
          file: dataUpload.file,
          images: dataUpload.image,
        };

        const response = await streamApi.sharePostStream(dataStream);

        if (!response.data) {
          throw new Error('Attempt to share stream post failed');
        }

        const { error, data, message } = response.data;

        if (error) {
          return { error, message };
        }

        // show alert success
        alertMessage({
          content: SUCCESS_MESSAGE.REPOST_SUCCESSFUL,
          alertType: 'snackbar',
          messageType: 'success',
          hasCloseIcon: true,
        });

        return { data, message };
      } catch (error) {
        return { error };
      }
    },
  ),
  postConversationStream: createAsyncThunk(
    actionType.POST_CONVERSATION_STREAM,
    // @ts-ignore
    async (streamData: any) => {
      const { file, image, ...otherStreamDatas } = streamData;

      try {
        // handle upload file
        const dataUpload = await uploadStreamFiles(file, image);

        // Cancel post process if there's an error uploading file
        if (dataUpload?.error) {
          throw new Error(ERROR_MESSAGE.STREAM_ERROR_UPLOAD_FILE);
        }

        const dataStream = {
          ...otherStreamDatas,
          file: dataUpload.file,
          images: dataUpload.image,
        };

        const response = await streamApi.postReplyStream(dataStream);

        if (!response.data) {
          throw new Error('Attempt to reply stream failed');
        }

        const { error, data, message } = response.data;

        if (error) {
          return { error, message };
        }

        return { data, message };
      } catch (error) {
        return { error };
      }
    },
  ),
};

// Reducers
const reducers = {};

// Extra reducers
const extraReducers = (builder) => {
  builder
    // Post stream -----------------------------------------------------
    .addCase(effects.postStream.pending, (state) => {
      state.composeStream.isLoading = true;
    })

    .addCase(effects.postStream.fulfilled, (state, action) => {
      const { message, error, data } = action.payload;

      if (error) {
        state.composeStream.error = error;
      } else {
        state.composeStream.data = data;
        state.composeStream.success = true;
      }

      state.composeStream.message = message;
      state.composeStream.isLoading = false;
    })

    .addCase(effects.postStream.rejected, (state, action) => {
      state.composeStream.error = action.payload.error;
      state.composeStream.isLoading = false;
    })

    // Update stream -----------------------------------------------------
    .addCase(effects.updatePostStream.pending, (state) => {
      state.updatePostStream.isLoading = true;
      state.updatePostStream.error = null;
    })
    .addCase(effects.updatePostStream.fulfilled, (state, action) => {
      const { message, error, data } = action.payload;

      if (error) {
        state.updatePostStream.error = error;
      } else {
        state.updatePostStream.data = data;
      }

      state.updatePostStream.message = message;
      state.updatePostStream.isLoading = false;
    })
    .addCase(effects.updatePostStream.rejected, (state, action) => {
      state.updatePostStream.error = action.payload.error;
      state.updatePostStream.isLoading = false;
    })

    // Share stream -----------------------------------------------------
    .addCase(effects.shareStream.pending, (state) => {
      state.shareStream.isLoading = true;
      state.shareStream.error = null;
    })
    .addCase(effects.shareStream.fulfilled, (state, action) => {
      const { message, error, data } = action.payload;

      if (error) {
        state.shareStream.error = error;
      } else {
        state.shareStream.data = data;
        state.shareStream.error = null;
      }

      state.shareStream.message = message;
      state.shareStream.isLoading = false;
    })
    .addCase(effects.shareStream.rejected, (state, action) => {
      state.shareStream.error = action.payload.error;
      state.shareStream.isLoading = false;
    })

    // Post convo stream
    .addCase(effects.postConversationStream.pending, (state) => {
      state.composeConversationStream.isLoading = true;
      state.composeConversationStream.error = null;
    })
    .addCase(effects.postConversationStream.fulfilled, (state, action) => {
      const { message, error, data } = action.payload;

      if (error) {
        state.composeConversationStream.error = error;
      } else {
        state.composeConversationStream.data = data;
        state.composeConversationStream.error = null;
      }

      state.composeConversationStream.message = message;
      state.composeConversationStream.isLoading = false;
    })
    .addCase(effects.postConversationStream.rejected, (state, action) => {
      state.composeConversationStream.error = action.payload.error;
      state.composeConversationStream.isLoading = false;
    });
};

// Slice
const stream = createSlice({
  name: 'stream',
  initialState,
  reducers,
  extraReducers,
});

export default stream;
