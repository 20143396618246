const ALLOWED_IMAGE_MIMETYPE = ['jpg', 'jpeg', 'png', 'gif'];
const ALLOWED_DOC_MIMETYPE = [
  'pdf',
  'doc',
  'docx',
  'ppt',
  'pptx',
  'xls',
  'xlsx',
];

export { ALLOWED_DOC_MIMETYPE, ALLOWED_IMAGE_MIMETYPE };
