/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-undef */
import { createAsyncThunk } from '@reduxjs/toolkit';

// related stores
import auth from 'utils/auth';

// api
import streamApi from 'lib/api/stream';
import watchlistApi from 'lib/api/watchlist';
import { getResearchData, getResearchIndicator } from 'lib/api/research';
import alertMessage from 'global/AlertMessage';
import SUCCESS_MESSAGE from 'constants/successMessage';
import { transformStreamV3, transformV3Response } from 'utils/stream';
import { STREAM_TYPE, STREAM_VERSION } from '../constants';
import { CommonPayload } from '../../../../@types/action-payload';
import {
  ChangeCommenterTypeParams,
  DeleteMultiplePostPayload,
  DeletePostPayload,
  FollowStreamLikersPayload,
  GetResearchParams,
  GetResearchPayload,
  GetStreamLikersPayload,
  GetStreamPayload,
  PinPostPayload,
  PostReportStreamPayload,
  PostVotePollingPayload,
  PostVoteTargetPricePayload,
} from '../types';

const STREAM_API: Record<number | string, any> = {
  [STREAM_TYPE.GENERIC]: streamApi.getStream,
  [STREAM_TYPE.USER]: streamApi.getUserStream,
  [STREAM_TYPE.SYMBOL]: streamApi.getSymbolStream,
};

// Actions ---------------------------------------------------------------------
// types
const CONTEXT = '@redux/streamWidget';

const actionType = {
  GET_STREAM: `${CONTEXT}/get_stream`,
  GET_RESEARCH: `${CONTEXT}/get_research`,
  LOAD_MORE_STREAM: `${CONTEXT}/load_more_stream`,
  LIKE_POST: `${CONTEXT}/like_post`,
  UNLIKE_POST: `${CONTEXT}/unlike_post`,
  POST_VOTE_TARGET_PRICE: `${CONTEXT}/vote_target_price`,
  POST_VOTE_POLLING: `${CONTEXT}/vote_polling`,
  FOLLOW_POST: `${CONTEXT}/follow_post`,
  UNFOLLOW_POST: `${CONTEXT}/unfollow_post`,
  SAVE_POST: `${CONTEXT}/save_post`,
  UNSAVE_POST: `${CONTEXT}/unsave_post`,
  GET_ANNOUNCEMENT_REPORTS: `${CONTEXT}/get_announcement_reports`,
  POST_REPORT_STREAM: `${CONTEXT}/post_report_stream`,
  DELETE_POST: `${CONTEXT}/delete_post`,
  DELETE_MULTIPLE_POST: `${CONTEXT}/delete_multiple_post`,
  PIN_POST: `${CONTEXT}/pin_post`,
  UNPIN_POST: `${CONTEXT}/unpin_post`,
  GET_STREAM_LIKERS: `${CONTEXT}/get_liker_post`,
  FOLLOW_STREAM_LIKERS: `${CONTEXT}/follow_liker_post`,
  CHANGE_COMMENTER_TYPE: `${CONTEXT}/CHANGE_COMMENTER_TYPE`,
  GET_RESEARCH_INDICATOR: `${CONTEXT}/GET_RESEARCH_INDICATOR`,
  UNTAG_POST: `${CONTEXT}/UNTAG_POST`,
  GET_COMPANY_PINNED_POST: `${CONTEXT}/get_pinned_post`,
};

// effects
const effects = {
  likePost: createAsyncThunk<CommonPayload, number>(
    actionType.LIKE_POST,
    async (postid = null) => {
      try {
        if (!postid) {
          throw new Error("Post id can't be null");
        }

        const response = await streamApi.likeStreamPost(postid);

        if (!response.data) {
          throw new Error('Attempt to like post failed');
        }

        const { error, data } = response.data;

        if (error) {
          return { error };
        }

        return { postid, data };
      } catch (error) {
        if (error?.cause?.message) {
          return { error: error?.cause?.message };
        }
        return { error: error.message };
      }
    },
  ),
  unlikePost: createAsyncThunk<CommonPayload, number>(
    actionType.UNLIKE_POST,
    async (postid = null) => {
      try {
        if (!postid) {
          throw new Error("Post id can't be null");
        }

        const response = await streamApi.unlikeStreamPost(postid);

        if (!response.data) {
          throw new Error('Attempt to unlike post failed');
        }

        const { error, data } = response.data;

        if (error) {
          return { error };
        }

        return { postid, data };
      } catch (error) {
        if (error?.cause?.message) {
          return { error: error?.cause?.message };
        }
        return { error: error.message };
      }
    },
  ),
  getStream: createAsyncThunk<CommonPayload, GetStreamPayload>(
    actionType.GET_STREAM,
    async ({
      type = 'all',
      category = 'latest',
      streamType = STREAM_TYPE.GENERIC,
      username = '',
      symbol = '',
      keyword,
      ...rest
    }) => {
      try {
        const API_VERSION = STREAM_VERSION.V3;
        const targetAPI = STREAM_API[streamType];
        let firstParam: string | number = API_VERSION;

        // TODO: Conditionally fetch api here
        if (streamType === STREAM_TYPE.USER) {
          if (!username) throw new Error('Username is required');
          firstParam = username;
        }

        if (streamType === STREAM_TYPE.SYMBOL) {
          if (!symbol) throw new Error('Symbol is required');
          firstParam = symbol;
        }

        const clean = keyword && keyword === '' ? null : 0;

        const response = await targetAPI(firstParam, {
          type,
          category,
          clean,
          keyword,
          ...rest,
        });

        if (!response.data) {
          throw new Error('Attempt to fetch stream failed');
        }

        const { data, message, error, error_type: errorType } = response.data;

        if (error || errorType) {
          return { message, error: errorType || error };
        }

        const streams = transformV3Response(data.stream);

        return {
          data: streams,
          message,
        };
      } catch (error) {
        return { error };
      }
    },
  ),
  getResearchStream: createAsyncThunk<GetResearchPayload, GetResearchParams>(
    actionType.GET_RESEARCH,
    async (params) => {
      const response = await getResearchData(params);

      if (!response.data) {
        throw new Error('Attempt to fetch stream research failed');
      }

      const { data, message, error_type: error } = response.data;

      if (error) {
        throw new Error(message);
      }

      return {
        data,
        message,
        loadMore: !!params.last_id,
      };
    },
  ),
  loadMoreStream: createAsyncThunk<CommonPayload, GetStreamPayload>(
    actionType.LOAD_MORE_STREAM,
    async ({
      type = 'all',
      category = 'latest',
      streamType = STREAM_TYPE.GENERIC,
      username = '',
      symbol = '',
      lastpost = 0,
      beforetimestamp = 0,
      keyword,
      ...rest
    }) => {
      try {
        const API_VERSION = STREAM_VERSION.V3;
        const targetAPI = STREAM_API[streamType];
        let firstParam: string | number = API_VERSION;

        if (streamType === STREAM_TYPE.USER) {
          if (!username) throw new Error('Username is required');
          firstParam = username;
        }

        if (streamType === STREAM_TYPE.SYMBOL) {
          if (!symbol) throw new Error('Symbol is required');
          firstParam = symbol;
        }

        const clean = keyword && keyword === '' ? null : 0;

        const response = await targetAPI(firstParam, {
          type,
          category,
          clean,
          keyword,
          beforelastpost: 1,
          lastpost,
          beforetimestamp,
          ...rest,
        });

        if (!response.data) {
          throw new Error('Attempt to fetch stream failed');
        }

        const { data, message, error, error_type: errorType } = response.data;

        if (error || errorType) {
          return { message, error: errorType || error };
        }

        const streams = transformV3Response(data.stream);

        return {
          data: streams,
          message,
        };
      } catch (error) {
        return { error };
      }
    },
  ),
  postVoteTargetPrice: createAsyncThunk<
    CommonPayload,
    PostVoteTargetPricePayload
  >(actionType.POST_VOTE_TARGET_PRICE, async ({ targetPriceId, agree }) => {
    try {
      const response = await streamApi.postVoteTargetPrice(
        targetPriceId,
        agree,
      );

      if (!response.data) {
        throw new Error('Attempt to vote target price failed');
      }

      const { data, message, error } = response.data;

      if (error) {
        return { message, error };
      }

      return {
        data,
        message,
      };
    } catch (error) {
      return { error };
    }
  }),
  postVotePolling: createAsyncThunk<CommonPayload, PostVotePollingPayload>(
    actionType.POST_VOTE_POLLING,
    async ({ pollingId, optionId }) => {
      try {
        const response = await streamApi.postPollingVote(pollingId, optionId);

        if (!response.data) {
          throw new Error('Attempt to vote polling failed');
        }

        const { data, message, error } = response.data;

        if (error) {
          return { message, error };
        }

        return { data, message };
      } catch (error) {
        return { error: error?.cause };
      }
    },
  ),

  followPost: createAsyncThunk<CommonPayload, number>(
    actionType.FOLLOW_POST,
    async (postid) => {
      try {
        const response = await streamApi.followPost(postid);

        if (!response.data) {
          throw new Error('Attempt to follow post failed');
        }

        const { data, error, message } = response.data;

        if (error) {
          return { message, error };
        }

        return { data, message, postid };
      } catch (error) {
        return { error };
      }
    },
  ),
  unfollowPost: createAsyncThunk<CommonPayload, number>(
    actionType.UNFOLLOW_POST,
    async (postid) => {
      try {
        const response = await streamApi.unfollowPost(postid);

        if (!response.data) {
          throw new Error('Attempt to unfollow post failed');
        }

        const { data, error, message } = response.data;

        if (error) {
          return { message, error };
        }

        return { data, message, postid };
      } catch (error) {
        return { error };
      }
    },
  ),

  // save/unsave post
  savePost: createAsyncThunk<CommonPayload, number>(
    actionType.SAVE_POST,
    async (postid) => {
      try {
        const response = await streamApi.savePost(postid);

        if (!response.data) {
          throw new Error('Attempt to save post failed');
        }

        const { data, error, message } = response.data;

        if (error) {
          return { message, error };
        }

        return { data, message, postid };
      } catch (error) {
        return { error };
      }
    },
  ),
  unsavePost: createAsyncThunk<CommonPayload, number>(
    actionType.UNSAVE_POST,
    async (postid) => {
      try {
        const response = await streamApi.unsavePost(postid);

        if (!response.data) {
          throw new Error('Attempt to unsave post failed');
        }

        const { error, message, data } = response.data;

        if (error) {
          return { error, message };
        }

        return { data, message, postid };
      } catch (error) {
        return { error };
      }
    },
  ),

  // get announcement reports
  getAnnouncementReports: createAsyncThunk<CommonPayload, number>(
    actionType.GET_ANNOUNCEMENT_REPORTS,
    async (postid) => {
      try {
        const response = await streamApi.getAnnouncementReports(postid);

        if (!response.data) {
          throw new Error('Attempt to get announcement report failed!');
        }

        const { error, message, data } = response.data;

        if (error) {
          return { error, message };
        }

        return { data, message, postid };
      } catch (error) {
        return { error };
      }
    },
  ),
  postReportStream: createAsyncThunk<CommonPayload, PostReportStreamPayload>(
    actionType.POST_REPORT_STREAM,
    async ({ postid, type, message }) => {
      try {
        const response = await streamApi.postReportStream(
          postid,
          type,
          message,
        );

        if (!response.data) {
          throw new Error('Attempt to report stream post failed');
        }

        const { error, data, message: resMessage } = response.data;

        if (error) {
          return { error, message: resMessage };
        }

        return { data, message: resMessage };
      } catch (error) {
        return { error };
      }
    },
  ),
  deleteStreamPost: createAsyncThunk<CommonPayload, DeletePostPayload>(
    actionType.DELETE_POST,
    async ({ postid, reason, sendEmail, isAuthor, type }) => {
      try {
        let response = null;
        if (isAuthor) {
          response = await streamApi.deleteOwnPostStream(postid);
        } else {
          response = await streamApi.deletePostStream(
            postid,
            reason,
            sendEmail,
          );
        }
        if (!response.data) {
          throw new Error('Attempt to delete post failed');
        }

        const { error, error_type, data, message } = response.data;

        if (error || error_type) {
          return { error: error || error_type, message };
        }

        return { data, message };
      } catch (error) {
        return { error };
      }
    },
  ),
  deleteMultipleStreamPost: createAsyncThunk<
    CommonPayload,
    DeleteMultiplePostPayload
  >(
    actionType.DELETE_MULTIPLE_POST,
    async ({ userid, reason, sendEmail, time }) => {
      try {
        const response = await streamApi.deleteMultiplePostStream(
          userid,
          time,
          reason,
          sendEmail,
        );

        if (!response.data) {
          throw new Error('Attempt to delete post failed');
        }

        const { error, error_type, data, message } = response.data;

        if (error || error_type) {
          return { error: error || error_type, message };
        }
        alertMessage({
          content: SUCCESS_MESSAGE.DELETE_STREAM_MULTIPLE_SUCCESSFUL,
          alertType: 'snackbar',
          messageType: 'success',
          hasCloseIcon: true,
        });
        return { data, message };
      } catch (error) {
        return { error };
      }
    },
  ),
  pinPost: createAsyncThunk<CommonPayload, PinPostPayload>(
    actionType.PIN_POST,
    async ({ postid, symbol = null }) => {
      try {
        const response = await streamApi.pinPost(postid, symbol);

        if (!response.data) {
          throw new Error('Attempt to pin post failed');
        }

        const { error, data, message } = response.data;

        if (error) {
          return { error, message };
        }

        return { data, message };
      } catch (error) {
        return { error };
      }
    },
  ),
  unpinPost: createAsyncThunk<CommonPayload, PinPostPayload>(
    actionType.UNPIN_POST,
    async ({ postid, symbol = null }) => {
      try {
        const response = await streamApi.unpinPost(postid, symbol);

        if (!response.data) {
          throw new Error('Attempt to pin post failed');
        }

        const { error, data, message } = response.data;

        if (error) {
          return { error, message };
        }

        return { data, message };
      } catch (error) {
        return { error };
      }
    },
  ),

  getStreamLikers: createAsyncThunk<CommonPayload, GetStreamLikersPayload>(
    actionType.GET_STREAM_LIKERS,
    async ({ postid, page }) => {
      try {
        // @ts-ignore
        const { username } = auth.getUserAccess();
        const response = await streamApi.getStreamLikers(
          username,
          postid,
          page,
        );

        if (!response.data) {
          throw new Error('Attempt to get stream likers failed');
        }

        const { error, data, message } = response.data;

        if (error) {
          return { message, error };
        }

        return { data, message, page, postid };
      } catch (error) {
        return { error };
      }
    },
  ),

  followStreamLikers: createAsyncThunk<
    CommonPayload,
    FollowStreamLikersPayload
  >(actionType.FOLLOW_STREAM_LIKERS, async ({ postid, userid, type }) => {
    try {
      // @ts-ignore
      const { watchlist_id: watchListId } = auth.getUserAccess();

      if (!watchListId) {
        throw new Error('Watchlist id is empty');
      }

      if (!userid) {
        throw new Error('User id is empty');
      }

      let targetAPI = watchlistApi.postFollowUser;

      if (type === 'UNFOLLOW') {
        targetAPI = watchlistApi.postUnfollowUser;
      }

      const response = await targetAPI(userid);

      if (!response.data) {
        throw new Error('Attempt to follow user failed');
      }
      const { error } = response.data;

      if (error) {
        return { error };
      }

      return { type, userid, postid };
    } catch (error) {
      return { error };
    }
  }),
  changeCommenter: createAsyncThunk<CommonPayload, ChangeCommenterTypeParams>(
    actionType.CHANGE_COMMENTER_TYPE,
    async ({ type, postid }) => {
      try {
        const response = await streamApi.changeCommenterType(type, postid);

        if (!response.data) {
          throw new Error('Attempt to change commenter type failed');
        }

        const { error, data, message } = response.data;

        if (error) {
          return { error, message };
        }

        return { data, message };
      } catch (error) {
        return { error };
      }
    },
  ),
  getResearchIndicator: createAsyncThunk<CommonPayload, string | null>(
    actionType.GET_RESEARCH_INDICATOR,
    async (symbol) => {
      try {
        const response = await getResearchIndicator(symbol);

        if (!response.data) {
          throw new Error('Attempt to get research indicator failed');
        }

        const { error, data, message } = response.data;

        if (error) {
          return { error, message };
        }

        return { data, message };
      } catch (error) {
        return { error };
      }
    },
  ),

  // untag post
  untagPost: createAsyncThunk<CommonPayload, string | null>(
    actionType.UNTAG_POST,
    async (postId) => {
      try {
        const response = await streamApi.untagPostTopic(postId);

        if (!response.data) {
          throw new Error('Attempt to untag post topic failed');
        }

        const { error, data, message } = response.data;

        if (error) {
          return { error, message };
        }

        alertMessage({
          content: SUCCESS_MESSAGE.UNTAG_POST_SUCCESSFUL,
          alertType: 'snackbar',
          messageType: 'success',
          hasCloseIcon: true,
        });

        return { data, error };
      } catch (error) {
        return { error };
      }
    },
  ),

  // get pinned post
  getCompanyPinnedPost: createAsyncThunk<CommonPayload, string>(
    actionType.GET_COMPANY_PINNED_POST,
    async (symbol) => {
      try {
        if (!symbol) {
          throw new Error('Symbol is required!');
        }

        const response = await streamApi.getCompanyPinnedPost(symbol);

        if (!response.data) {
          throw new Error('Attempt to get company pinned post failed');
        }

        const { error_type: error, data, message } = response.data;

        // mocks data
        // const data = pinnedPostMocksData;

        if (error) {
          return { error, message };
        }

        const stream = transformStreamV3(data);

        return {
          data: stream,
          message,
          symbol,
        };
      } catch (error) {
        return { error };
      }
    },
  ),
};

export default effects;
