import axios from 'axios';
import {
  GIPHY_API_KEY,
  GIPHY_URL,
  GIPHY_STICKERS_URL,
} from 'constants/api';

const SEARCH_GIF_URL = `${GIPHY_URL}/search`;
const TRENDING_GIF_URL = `${GIPHY_URL}/trending`;
const SEARCH_STICKER_URL = `${GIPHY_STICKERS_URL}/search`;
const TRENDING_STICKER_URL = `${GIPHY_STICKERS_URL}/trending`;

const getSearchGif = (keyword, offset = 0) =>
  axios.get(SEARCH_GIF_URL, {
    params: {
      api_key: GIPHY_API_KEY,
      q: keyword,
      limit: '30',
      offset,
      rating: 'G',
      lang: 'en',
    },
  });

const getTrendingGif = (offset = 0) =>
  axios.get(TRENDING_GIF_URL, {
    params: {
      api_key: GIPHY_API_KEY,
      limit: '30',
      offset,
      rating: 'G',
    },
  });

const getSearchSticker = (keyword, offset = 0) =>
  axios.get(SEARCH_STICKER_URL, {
    params: {
      api_key: GIPHY_API_KEY,
      q: keyword,
      limit: '30',
      offset,
      rating: 'G',
      lang: 'en',
    },
  });

const getTrendingSticker = (offset = 0) =>
  axios.get(TRENDING_STICKER_URL, {
    params: {
      api_key: GIPHY_API_KEY,
      limit: '30',
      offset,
      rating: 'G',
    },
  });

export default {
  getSearchGif,
  getTrendingGif,
  getSearchSticker,
  getTrendingSticker,
};
