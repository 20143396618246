import { createSlice, createSelector } from '@reduxjs/toolkit';

// api
import { categories, streamTypes, STREAM_TABS } from '../constants';

import streamEffects from './slice.effects';
import streamExtraReducers from './slice.extra-reducer';

// initial state
export const initialState = {
  list: [],
  listResearch: [],
  newPostCount: 0,
  isLoading: true,
  isLoadingMore: false,
  error: null,
  message: '',
  categories,
  types: streamTypes,
  tabHeader: STREAM_TABS[0].value,
  selectedCategory: 'latest',
  selectedType: 'ideas',
  filterParams: {
    type: 'latest',
    category: '',
    keyword: '',
    from: '',
    to: '',
    reporttype: null,
    date: null,
  },
  postVoteTargetPrice: {
    isLoading: false,
    data: {},
    error: null,
    message: '',
  },
  postVotePolling: {
    data: {},
    isLoading: false,
    error: null,
    message: '',
  },
  toggleFollowPost: {
    data: {},
    isLoading: false,
    error: null,
    message: '',
  },
  toggleSavePost: {
    data: {},
    isLoading: false,
    error: null,
    message: '',
  },
  togglePinPost: {
    data: null,
    isLoading: false,
    error: null,
    message: '',
  },
  announcementReports: {
    data: {},
    isLoading: false,
    error: null,
    message: '',
  },
  streamLikers: {
    data: {},
    isLoading: false,
    error: null,
    message: '',
  },
  companyPinnedPost: {
    data: null,
    isLoading: false,
    error: null,
    message: '',
  },
  hasNewResearch: false,
  newResearchCount: 1,
};

export type StreamState = typeof initialState;

// Selectors -------------------------------------------------------------------
const streamWidgetSelector = (state) => state.streamWidget;
export const selectors = createSelector(
  streamWidgetSelector,
  (state: StreamState) => ({
    ...state,
  }),
);

// effects
export const effects = streamEffects;

// Reducers ------------------------------------------------------------------
const reducers = {
  changeType(state, action) {
    state.selectedType = action.payload;
  },
  changeCategory(state, action) {
    state.selectedCategory = action.payload;
  },
  setFilterParam: (state, action) => {
    state.filterParams = {
      ...state.filterParams,
      ...action.payload,
    };
  },
  resetFilterParam: (state) => {
    state.filterParams = initialState.filterParams;
    state.newPostCount = 0;
  },
  addNewPostCount: (state) => {
    state.newPostCount += 1;
  },
  setTabHeader: (state: StreamState, action) => {
    state.tabHeader = action.payload;
  },
  removeHasNewResearch: (state) => {
    state.hasNewResearch = false;
  },
};

// Extra Reducer ---------------------------------------------------------------
const extraReducers = streamExtraReducers;

// Slice -----------------------------------------------------------------------
const streamSlice = createSlice({
  name: 'streamWidget',
  initialState,
  reducers,
  extraReducers,
});

export default streamSlice;
