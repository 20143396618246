import { PayloadAction } from '@reduxjs/toolkit';

// related stores
import handleErrorMessageAPI from 'global/AlertErrorMessage';
import ERROR_MESSAGE from 'constants/errorMessage';
import SUCCESS_MESSAGE from 'constants/successMessage';

// api
import { effects as moderationEffects } from 'lib/entities/moderation';
import alertMessage from 'global/AlertMessage';
import { CommonPayload } from '../../../../@types/action-payload';
import {
  GetResearchPayload,
  StreamState,
  StreamWithPostPayload,
} from '../types';

import effects from './slice.effects';

const STATUS_FOLLOWING = 1;
const STATUS_UNFOLLOWING = 0;

const STATUS_SAVED = 1;
const STATUS_UNSAVED = 0;

const STATUS_TRENDING = 1;
const STATUS_NOT_TRENDING = 0;

const extraReducers = (builder) => {
  builder
    // Get and load more stream --------------------------------------------------
    .addCase(effects.getStream.pending, (state: StreamState) => {
      state.isLoading = true;
      state.error = null;
      state.list = [];
    })
    .addCase(
      effects.getStream.fulfilled,
      (state: StreamState, action: PayloadAction<CommonPayload>) => {
        const { data, message, error } = action.payload;
        if (error) {
          state.error = error;
        } else {
          state.list = data;
          state.error = null;
          state.newPostCount = 0;
        }

        state.message = message;
        state.isLoading = false;
      },
    )
    .addCase(
      effects.getStream.rejected,
      (state: StreamState, action: PayloadAction<CommonPayload>) => {
        state.error = action.payload.error;
        state.isLoading = false;
      },
    )
    .addCase(effects.loadMoreStream.pending, (state: StreamState) => {
      state.isLoadingMore = true;
      state.error = null;
    })
    .addCase(
      effects.loadMoreStream.fulfilled,
      (state: StreamState, action: PayloadAction<CommonPayload>) => {
        const { data, error, message } = action.payload;

        if (error) {
          state.error = error;
        } else {
          state.error = null;
          const currentList = state.list.map((i) => ({ ...i }));
          const newList = [...currentList, ...data];

          state.list = newList;
        }

        state.message = message;
        state.isLoadingMore = false;
      },
    )
    .addCase(
      effects.loadMoreStream.rejected,
      (state: StreamState, action: PayloadAction<CommonPayload>) => {
        state.error = action.payload.error;
        state.isLoadingMore = false;
      },
    )
    // Get stream research
    .addCase(
      effects.getResearchStream.pending,
      (state: StreamState, action: PayloadAction<GetResearchPayload, any, any>) => {
        const { last_id: lastId } = action.meta.arg;

        if (lastId) {
          state.isLoadingMore = true;
        } else {
          state.isLoading = true;
          state.error = null;
          state.listResearch = [];
        }
      },
    )
    .addCase(
      effects.getResearchStream.fulfilled,
      (state: StreamState, action: PayloadAction<GetResearchPayload>) => {
        const { error, data, message, loadMore } = action.payload;

        if (error) {
          state.error = error;
        }

        if (!loadMore) {
          state.listResearch = data;
        } else {
          state.listResearch.push(...data);
        }

        state.message = message;
        state.isLoading = false;
        state.isLoadingMore = false;
      },
    )
    .addCase(
      effects.getResearchStream.rejected,
      (state: StreamState, action: PayloadAction) => {
        state.listResearch = [];
        state.isLoading = false;
        state.error = action.payload;
      },
    )

    // Like post
    .addCase(
      effects.likePost.fulfilled,
      (state: StreamState, action: PayloadAction<StreamWithPostPayload>) => {
        const { error, postid, data } = action.payload;
        if (error) {
          handleErrorMessageAPI(error, ERROR_MESSAGE.ALERT_RED);
        } else {
          const postIdx = state.list.findIndex(
            (post) => post.postid === postid,
          );

          if (postIdx > -1) {
            state.list[postIdx].liked = data.liked;
            state.list[postIdx].likes = data.likes;
          }

          if (state.companyPinnedPost.data?.postid === postid) {
            state.companyPinnedPost.data = {
              ...state.companyPinnedPost.data,
              liked: data.liked,
              likes: data.likes,
            };
          }
        }
      },
    )
    // Unlike post
    .addCase(
      effects.unlikePost.fulfilled,
      (state: StreamState, action: PayloadAction<StreamWithPostPayload>) => {
        const { error, postid, data } = action.payload;

        if (error) {
          handleErrorMessageAPI(error, ERROR_MESSAGE.ALERT_RED);
        } else {
          const postIdx = state.list.findIndex(
            (post) => post.postid === postid,
          );

          if (postIdx > -1) {
            state.list[postIdx].liked = data.liked;
            state.list[postIdx].likes = data.likes;
          }

          if (state.companyPinnedPost.data?.postid === postid) {
            state.companyPinnedPost.data = {
              ...state.companyPinnedPost.data,
              liked: data.liked,
              likes: data.likes,
            };
          }
        }
      },
    )

    // Vote target price ---------------------------------------------------------
    .addCase(effects.postVoteTargetPrice.pending, (state: StreamState) => {
      state.postVoteTargetPrice.isLoading = true;
      state.postVoteTargetPrice.error = null;
    })
    .addCase(
      effects.postVoteTargetPrice.fulfilled,
      (state: StreamState, action: PayloadAction<CommonPayload, any, any>) => {
        const { error, data, message } = action.payload;
        const { postid } = action.meta.arg;

        if (error) {
          state.postVoteTargetPrice.error = error;
        } else {
          state.postVoteTargetPrice.error = null;

          // update state list
          const postIdx = state.list.findIndex(
            (post) => post.postid === data.postid,
          );
          if (postIdx > -1) {
            state.list[postIdx] = data;
          }

          if (state.companyPinnedPost.data?.postid === postid) {
            state.companyPinnedPost.data = {
              ...state.companyPinnedPost.data,
              target_price: data?.target_price,
            };
          }
        }

        state.postVoteTargetPrice.message = message;
        state.postVoteTargetPrice.isLoading = false;
      },
    )

    .addCase(
      effects.postVoteTargetPrice.rejected,
      (state: StreamState, action: PayloadAction<CommonPayload>) => {
        state.postVoteTargetPrice.error = action.payload.error;
        state.postVoteTargetPrice.isLoading = false;
      },
    )

    // Vote polling --------------------------------------------------------------
    .addCase(effects.postVotePolling.pending, (state: StreamState) => {
      state.postVotePolling.isLoading = true;
      state.postVotePolling.error = null;
    })
    .addCase(
      effects.postVotePolling.fulfilled,
      (state: StreamState, action: PayloadAction<CommonPayload, any, any>) => {
        const { data, message, error } = action.payload;
        const { postid } = action.meta.arg;

        if (error) {
          state.postVotePolling.error = error;
        } else {
          state.postVotePolling.error = null;

          const postIdx = state.list.findIndex(
            (post) => post.polling.length > 0 && post.polling[0].id === data.id,
          );

          if (postIdx > -1) {
            state.list[postIdx].polling[0] = data;
          }

          if (state.companyPinnedPost.data?.postid === postid) {
            state.companyPinnedPost.data = {
              ...state.companyPinnedPost.data,
              polling: [data],
            };
          }
        }

        state.postVotePolling.message = message;
        state.postVotePolling.isLoading = false;
      },
    )
    .addCase(
      effects.postVotePolling.rejected,
      (state: StreamState, action: PayloadAction<CommonPayload>) => {
        state.postVotePolling.error = action.payload.error;
        state.postVotePolling.isLoading = false;
      },
    )

    // Follow/unfollow post -------------------------------------------------------
    .addCase(effects.followPost.pending, (state: StreamState) => {
      state.toggleFollowPost.isLoading = true;
      state.toggleFollowPost.error = null;
    })
    .addCase(
      effects.followPost.fulfilled,
      (state: StreamState, action: PayloadAction<StreamWithPostPayload>) => {
        const { data, error, message, postid } = action.payload;

        if (error) {
          state.toggleFollowPost.error = error;
        } else {
          state.toggleFollowPost.data = data;
          state.toggleFollowPost.error = null;

          const postIdx = state.list.findIndex(
            (post) => post.postid === postid,
          );

          if (postIdx > -1) {
            state.list[postIdx].follow = STATUS_FOLLOWING;
          }

          if (state.companyPinnedPost.data?.postid === postid) {
            state.companyPinnedPost.data = {
              ...state.companyPinnedPost.data,
              follow: STATUS_FOLLOWING,
            };
          }
        }

        state.toggleFollowPost.isLoading = false;
        state.toggleFollowPost.message = message;
      },
    )
    .addCase(
      effects.followPost.rejected,
      (state: StreamState, action: PayloadAction<CommonPayload>) => {
        state.toggleFollowPost.error = action.payload.error;
        state.toggleFollowPost.isLoading = false;
      },
    )
    .addCase(effects.unfollowPost.pending, (state: StreamState) => {
      state.toggleFollowPost.isLoading = true;
      state.toggleFollowPost.error = null;
    })
    .addCase(
      effects.unfollowPost.fulfilled,
      (state: StreamState, action: PayloadAction<StreamWithPostPayload>) => {
        const { data, error, message, postid } = action.payload;

        if (error) {
          state.toggleFollowPost.error = error;
        } else {
          state.toggleFollowPost.data = data;
          state.toggleFollowPost.error = null;

          const postIdx = state.list.findIndex(
            (post) => post.postid === postid,
          );

          if (postIdx > -1) {
            state.list[postIdx].follow = STATUS_UNFOLLOWING;
          }

          if (state.companyPinnedPost.data?.postid === postid) {
            state.companyPinnedPost.data = {
              ...state.companyPinnedPost.data,
              follow: STATUS_UNFOLLOWING,
            };
          }
        }

        state.toggleFollowPost.isLoading = false;
        state.toggleFollowPost.message = message;
      },
    )
    .addCase(
      effects.unfollowPost.rejected,
      (state: StreamState, action: PayloadAction<StreamWithPostPayload>) => {
        state.toggleFollowPost.error = action.payload.error;
        state.toggleFollowPost.isLoading = false;
      },
    )

    // Save/unsave post -------------------------------------------------------
    .addCase(effects.savePost.pending, (state: StreamState) => {
      state.toggleSavePost.isLoading = true;
      state.toggleSavePost.error = null;
    })
    .addCase(
      effects.savePost.fulfilled,
      (state: StreamState, action: PayloadAction<StreamWithPostPayload>) => {
        const { error, message, postid, data } = action.payload;

        if (error) {
          state.toggleSavePost.error = error;
        } else {
          state.toggleSavePost.data = data;
          state.toggleSavePost.error = null;

          const postIdx = state.list.findIndex(
            (post) => post.postid === postid,
          );

          if (postIdx > -1) {
            state.list[postIdx].saved = STATUS_SAVED;
          }

          if (state.companyPinnedPost.data?.postid === postid) {
            state.companyPinnedPost.data = {
              ...state.companyPinnedPost.data,
              saved: STATUS_SAVED,
            };
          }
        }

        state.toggleSavePost.message = message;
        state.toggleSavePost.isLoading = false;
      },
    )
    .addCase(
      effects.savePost.rejected,
      (state: StreamState, action: PayloadAction<StreamWithPostPayload>) => {
        state.toggleSavePost.error = action.payload.error;
        state.toggleSavePost.isLoading = false;
      },
    )
    .addCase(effects.unsavePost.pending, (state: StreamState) => {
      state.toggleSavePost.isLoading = true;
      state.toggleSavePost.error = null;
    })
    .addCase(
      effects.unsavePost.fulfilled,
      (state: StreamState, action: PayloadAction<StreamWithPostPayload>) => {
        const { error, message, postid, data } = action.payload;

        if (error) {
          state.toggleSavePost.error = error;
        } else {
          state.toggleSavePost.data = data;
          state.toggleSavePost.error = null;

          const postIdx = state.list.findIndex(
            (post) => post.postid === postid,
          );

          if (postIdx > -1) {
            state.list[postIdx].saved = STATUS_UNSAVED;
          }

          if (state.companyPinnedPost.data?.postid === postid) {
            state.companyPinnedPost.data = {
              ...state.companyPinnedPost.data,
              saved: STATUS_UNSAVED,
            };
          }
        }

        state.toggleSavePost.message = message;
        state.toggleSavePost.isLoading = false;
      },
    )
    .addCase(
      effects.unsavePost.rejected,
      (state: StreamState, action: PayloadAction<StreamWithPostPayload>) => {
        state.toggleSavePost.error = action.payload.error;
        state.toggleSavePost.isLoading = false;
      },
    )

    // Get announcement reports --------------------------------------------------
    .addCase(effects.getAnnouncementReports.pending, (state: StreamState) => {
      state.announcementReports.isLoading = true;
      state.announcementReports.error = null;
    })
    .addCase(
      effects.getAnnouncementReports.fulfilled,
      (state: StreamState, action: PayloadAction<StreamWithPostPayload>) => {
        const { error, data, message, postid } = action.payload;

        if (error) {
          state.announcementReports.error = error;
        } else {
          state.announcementReports[postid] = data;
        }

        state.announcementReports.message = message;
        state.announcementReports.isLoading = false;
      },
    )
    .addCase(
      effects.getAnnouncementReports.rejected,
      (state: StreamState, action: PayloadAction<CommonPayload>) => {
        state.announcementReports.error = action.payload.error;
        state.announcementReports.isLoading = false;
      },
    )

    // Pin unpin post ------------------------------------------------------------
    .addCase(effects.pinPost.pending, (state: StreamState) => {
      state.togglePinPost.isLoading = true;
      state.togglePinPost.error = null;
    })
    .addCase(
      effects.pinPost.fulfilled,
      (state: StreamState, action: PayloadAction<CommonPayload>) => {
        const { error, data, message } = action.payload;
        if (error) {
          state.togglePinPost.error = error;
        } else {
          alertMessage({
            content: SUCCESS_MESSAGE.POST_PINNED_SUCCESSFUL,
            alertType: 'snackbar',
            messageType: 'success',
            hasCloseIcon: true,
          });
          state.togglePinPost.data = data;
        }
        state.togglePinPost.isLoading = false;
        state.togglePinPost.message = message;
      },
    )
    .addCase(
      effects.pinPost.rejected,
      (state: StreamState, action: PayloadAction<CommonPayload>) => {
        state.togglePinPost.error = action.payload.error;
        state.togglePinPost.isLoading = false;
      },
    )
    .addCase(effects.unpinPost.pending, (state: StreamState) => {
      state.togglePinPost.isLoading = true;
      state.togglePinPost.error = null;
    })
    .addCase(
      effects.unpinPost.fulfilled,
      (state: StreamState, action: PayloadAction<CommonPayload>) => {
        const { error, data, message } = action.payload;
        if (error) {
          state.togglePinPost.error = error;
        } else {
          alertMessage({
            content: SUCCESS_MESSAGE.POST_UNPINNED_SUCCESSFUL,
            alertType: 'snackbar',
            messageType: 'success',
            hasCloseIcon: true,
          });
          state.togglePinPost.data = data;
        }
        state.togglePinPost.isLoading = false;
        state.togglePinPost.message = message;
      },
    )
    .addCase(
      effects.unpinPost.rejected,
      (state: StreamState, action: PayloadAction<CommonPayload>) => {
        state.togglePinPost.error = action.payload.error;
        state.togglePinPost.isLoading = false;
      },
    )
    // Report stream doesn't affect reducer, extra reducer for this is unnecessary

    // Get stream likers ---------------------------------------------------------
    .addCase(effects.getStreamLikers.pending, (state: StreamState) => {
      state.streamLikers.isLoading = true;
      state.streamLikers.error = null;
    })
    .addCase(
      effects.getStreamLikers.fulfilled,
      (state: StreamState, action: PayloadAction<StreamWithPostPayload>) => {
        const { error, message, data, page, postid } = action.payload;

        if (error) {
          state.streamLikers.error = error;
        } else {
          // eslint-disable-next-line no-lonely-if
          if (page > 1) {
            const currentList = state.streamLikers.data[postid].likers.map((s) => ({
              ...s,
            }));
            const newList = [...currentList, ...data.likers];
            state.streamLikers.data[postid].likers = newList;
            state.streamLikers.data[postid].data = newList;
            state.streamLikers.data[postid].more = data.more;
          } else {
            state.streamLikers.data[postid] = data;
          }
        }

        state.streamLikers.message = message;
        state.streamLikers.isLoading = false;
      },
    )
    .addCase(
      effects.getStreamLikers.rejected,
      (state: StreamState, action: PayloadAction<CommonPayload>) => {
        state.streamLikers.error = action.payload.error;
        state.streamLikers.isLoading = false;
      },
    )

    // Follow stream likers
    .addCase(
      effects.followStreamLikers.fulfilled,
      (state: StreamState, action: PayloadAction<StreamWithPostPayload>) => {
        const { error, type, postid, userid } = action.payload;

        if (!error && state.streamLikers.data[postid]) {
          const userIdx = state.streamLikers.data[postid].likers?.findIndex(
            (user) => user.id === userid,
          );
          if (userIdx > -1) {
            state.streamLikers.data[postid].likers[userIdx].followed =
            type === 'FOLLOW';
          }
        }
      },
    )

    // Delete Stream ---------------------------------------------------------
    .addCase(
      effects.deleteStreamPost.fulfilled,
      (
        state: StreamState,
        action: PayloadAction<StreamWithPostPayload, any, any>,
      ) => {
        const { error } = action.payload;
        const { postid, type } = action.meta.arg;

        if (!error) {
          const postIdx = state.list.findIndex(
            (post) => post.postid === postid,
          );
          alertMessage({
            content: `${SUCCESS_MESSAGE.POST_SUCCESSFUL_DELETED} ${type}`,
            alertType: 'snackbar',
            messageType: 'success',
            hasCloseIcon: true,
          });
          if (postIdx > -1) {
            state.list.splice(postIdx, 1);
          }
        }
      },
    )

    .addCase(
      effects.changeCommenter.fulfilled,
      (state: StreamState, action: PayloadAction<CommonPayload, any, any>) => {
        const { error } = action.payload;
        const { postid, type, label } = action.meta.arg;

        if (!error) {
          const postIdx = state.list.findIndex(
            (post) => post.postid === postid,
          );
          const successMessage = `${label} can comment now`;
          alertMessage({
            content: successMessage,
            alertType: 'snackbar',
            messageType: 'success',
            hasCloseIcon: true,
          });
          if (postIdx > -1) {
            state.list[postIdx].commenter_type = type;
          }
        }
      },
    )

    // Research Indicator
    .addCase(
      effects.getResearchIndicator.fulfilled,
      (state: StreamState, action: PayloadAction<CommonPayload, any, any>) => {
        const { error, data } = action.payload;

        if (!error) {
          state.hasNewResearch = data?.has_new;
          state.newResearchCount = data?.count;
        }
      },
    )

    // Get Company Pinned Post
    .addCase(effects.getCompanyPinnedPost.pending, (state: StreamState) => {
      state.companyPinnedPost.isLoading = true;
      state.companyPinnedPost.error = null;
      state.companyPinnedPost.data = null;
    })
    .addCase(
      effects.getCompanyPinnedPost.fulfilled,
      (
        state: StreamState,
        action: PayloadAction<CommonPayload>,
      ) => {
        const { error, message, data } = action.payload;

        if (error) {
          state.companyPinnedPost.error = error;
        } else {
          state.companyPinnedPost.data = data;
          state.companyPinnedPost.error = null;
        }

        state.companyPinnedPost.message = message;
        state.companyPinnedPost.isLoading = false;
      },
    )
    .addCase(
      effects.getCompanyPinnedPost.rejected,
      (
        state: StreamState,
        action: PayloadAction<CommonPayload>,
      ) => {
        state.companyPinnedPost.isLoading = false;
        state.companyPinnedPost.error = action.payload.error;
      },
    );
};

export default extraReducers;
