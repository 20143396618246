/* eslint-disable import/named */
import requestConfig from 'config/requestExodus';

import { API_EXODUS_URL } from 'constants/api';
import { APIResponse } from '../../../@types/api-response';

const researchUrl = `${API_EXODUS_URL}/research`;

const request = requestConfig();

export const getCategories = (): Promise<APIResponse> =>
  request.get(`${researchUrl}/categories`);

interface GetResearchParams {
  keyword?: string;
  last_id?: number;
  category_id?: number;
  symbol?: string;
}

export const getResearchData = (
  params: GetResearchParams,
): Promise<APIResponse> => request.get(researchUrl, { params });

export const getResearchIndicator = (symbol?: string): Promise<APIResponse> => {
  const params = symbol ? { symbol } : undefined;
  return request.get(`${researchUrl}/indicator/new`, { params });
};
